<template>
  <div>
    <MainSearchBar />
    <!-- <Advertisement /> -->
    <Categories />
    <BrowseCities />
    <LatestAgenciesList />
    <LatestEventsList />
    <LatestInterviewsList />
    <LatesJobsList />
  </div>
</template>

<script>
export default {
  components: {
    // Advertisement: () => import('@/components/Advertisement.vue'),
    MainSearchBar: () => import('@/components/MainSearchBar.vue'),
    Categories: () => import('@/components/Categories.vue'),
    LatestAgenciesList: () => import('@/components/LatestAgenciesList.vue'),
    LatestEventsList: () => import('@/components/LatestEventsList.vue'),
    LatestInterviewsList: () => import('@/components/LatestInterviewsList.vue'),
    LatesJobsList: () => import('@/components/LatesJobsList.vue'),
    BrowseCities: () => import('@/components/BrowseCities.vue')
  }
}
</script>
